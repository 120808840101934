<template>
	<div :class="$style.topbar">
		<div class="mb-0 mr-auto d-xl-block d-none">
			<!-- <cui-project-management /> -->
		</div>
		<div class="mr-4 d-none d-sm-block">
		</div>
		<div class="mr-4 d-none d-sm-block">
		</div>
		<div>
			<cui-user-menu />
		</div>
	</div>
</template>

<script>
// import CuiProjectManagement from './ProjectManagement'
import CuiUserMenu from './UserMenu'

export default {
	components: {
		// CuiProjectManagement,
		CuiUserMenu,
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
