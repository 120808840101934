import Vue from 'vue'
import Vuex from 'vuex'
import * as projectsAPI from '@/services/projects'
import utilities from '@/services/utilities'
import router from '@/router'
import _ from 'lodash'
import moment from 'moment'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

const dateFormat = 'YYYY-MM-DD'

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		projectsList: [],
		actualPurchaseOrder: {},
		actualRecord: {
			general: {},
			suppliers: {
				data: [],
				pagination: {
					current: 0,
					total: 0,
					perPage: 25,
				},
			},
			services: {},
		},
		filters: {
			status: '0',
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
			defaultDay: [moment().startOf('day').format(dateFormat), moment().endOf('day').format(dateFormat)],
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			projectsAPI
				.getAll()
				.then((response) => {
					commit('SET_STATE', {
						projectsList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.getOne(payload)
					.then((response) => {
						commit('SET_STATE', {
							actualRecord: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
						})
						commit('SET_STATE', {
							actualRecord: {},
						})
						console.log('projects/GET_ONE::error-->', error)
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			projectsAPI
				.create(payload)
				.then((response) => {
					// console.log('response-->', response)
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					dispatch('GET_ONE', { project_id: response.data.id }).then(() => {
						router.replace(`/projects/${response.data.id}`)
					})
				})
				.catch((error) => {
					// console.log('error-->', error)
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			projectsAPI
				.update(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					dispatch('GET_ONE', { project_id: payload.id })
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			projectsAPI
				.toggle(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.projects'),
						description: response.message,
					})

					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.projects'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async TOGGLE_SUPPLIERS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.toggleSuppliers(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: response.message,
						})
						resolve(response)
					})
					.catch((error) => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
			})
		},
		async GET_SUPPLIER_SERVICES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.getSupplierServices(payload)
					.then((response) => {
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async DELETE_SUPPLIER_SERVICE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.deleteSupplierService(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.errorEdit')),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async ADD_SUPPLIER_SERVICES({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.addSupplierService(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.errorEdit')),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GENERAL_NOTIFICATION_CLOSED_PROJECT({ commit }, project_id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.generalNotificationClosedProject(project_id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.errorEdit')),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async PARTICULAR_NOTIFICATION_CLOSED_PROJECT({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				projectsAPI
					.particularNotificationClosedProject(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.errorEdit')),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		projectsList: (state) => state.projectsList,
		actualRecord: (state) => state.actualRecord,
		filters: (state) => state.filters,
		actualPurchaseOrder: (state) => state.actualPurchaseOrder,
	},
}
