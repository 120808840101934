var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',[_c('a-layout-content',[_c('div',{class:{
			[_vm.$style.container]: true,
			cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
			cui__layout__cardsShadow: _vm.settings.isCardShadow,
			cui__layout__borderless: _vm.settings.isBorderless,
			[_vm.$style.white]: _vm.settings.authPagesColor === 'white',
			[_vm.$style.gray]: _vm.settings.authPagesColor === 'gray',
		},style:({ backgroundImage: _vm.settings.authPagesColor === 'image' ? `url(${_vm.settings.backgroundImage})` : 'none' })},[_c('div',{class:_vm.$style.topbar}),_c('div',{class:_vm.$style.containerInner},[_c('transition',{attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"}},[_c('router-view')],1)],1),_c('div',{staticClass:"mt-auto pb-5 pt-5"},[_c('div',{staticClass:"text-center"},[_vm._v("Copyright © 2024 Origami Group")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }