import Vue from 'vue'
import Vuex from 'vuex'
import * as weekPeriodsAPI from '@/services/weekPeriods'
import utilities from '@/services/utilities'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		actualRecord: {},
		list: [],
		actualSupplierProject: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, project_id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			weekPeriodsAPI
				.get(project_id)
				.then((response) => {
					commit('SET_STATE', {
						list: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.sectors'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
					commit('SET_STATE', {
						list: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				weekPeriodsAPI
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
						})
						dispatch('GET', payload.project_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async DELETE({ commit, dispatch }, { id, project_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				weekPeriodsAPI
					.toggle(id)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
						})
						dispatch('GET', project_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorDisable')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				weekPeriodsAPI
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
						})
						dispatch('GET', payload.project_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.sectors'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async IMPORT({ commit, dispatch }, { formData, project_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				weekPeriodsAPI
					.importCatalog(formData)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.projects'),
							description: response.message,
						})
						dispatch('GET', project_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		actualRecord: (state) => state.actualRecord,
		list: (state) => state.list,
		actualSupplierProject: (state) => state.actualSupplierProject,
	},
}
