
const messages = {
	menu: {
		system: 'System',
		fileSettings: 'File settings',
		users: 'Users',
	},
	fileSettings: {
		tableHeaders: {
			description: 'Description',
			extension: 'Extension',
			maxWeight: 'Maximum weight',
			periodicity: 'Periodicity',
		},
	},
	users: {
		tableHeaders: {
			id: 'ID',
			fullName: 'Full name',
			email: 'Email',
			phone: 'Telephone',
			role: 'Role',
		},
		edit: {
			title: 'General information',
			names: 'Name(s)',
			surname: 'LastNames',
			password: 'Password',
			confirmPassword: 'Confirm password',
			generatePassword: 'Generate password',
			passwordMsg: 'Enter password of at least 6 characters.',
			confirmPasswordMsg: 'The passwords do not match.',
		},
	},
}

export default messages;
