import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/files')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/files/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/files', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(requestPayload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/files`, requestPayload)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/files/toggle/${id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getSecureURL({ entity, id }) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/files/secure-url/${entity}/${id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
