import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'
import utilities from '@/services/utilities'

const apiClient = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api`,
	timeout: 1000 * 50,
})

apiClient.interceptors.request.use((request) => {
	const accessToken = store.get('accessToken')
	if (accessToken) {
		request.headers = {
			...request.headers,
			Authorization: `Bearer ${accessToken}`,
			'Access-Control-Allow-Origin': true,
		}
	}
	return request
})

export default apiClient
