import Vue from 'vue'
import Vuex from 'vuex'
import * as dashboardAPI from '@/services/dashboard'
import utilities from '@/services/utilities'
import _ from 'lodash'
import moment from 'moment'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		panelData: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET_DATA({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			return new Promise((resolve, reject) => {
				dashboardAPI
					.getData()
					.then((response) => {
						commit('SET_STATE', {
							panelData: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.projects'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		panelData: (state) => state.panelData,
	},
}
