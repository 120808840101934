import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get(project_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/week-periods/${project_id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/week-periods', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put('/week-periods', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/week-periods/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function importCatalog(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/week-periods/import-layout`, payload)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
