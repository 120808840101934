export default Object.freeze({
	'd': {
		label: 'Diario',
		labelDetail: 'Días',
	},
	'w': {
		label: 'Semanal',
		labelDetail: 'Semanas',
	},
	'q': {
		label: 'Catorcenal',
		labelDetail: 'Catorcenas',
	},
	'm': {
		label: 'Mensual',
		labelDetail: 'Meses',
	},
	'u': {
		label: 'Único',
		labelDetail: 'Pago único',
	},
})
