import Vue from 'vue'
import Vuex from 'vuex'
import * as users from '@/services/users'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'
import _ from 'lodash'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		usersList: [],
		actualUser: {},
		menu: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			users
				.getAll()
				.then((response) => {
					commit('SET_STATE', {
						usersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			users
				.getOne(id)
				.then((response) => {
					commit('SET_STATE', {
						actualUser: _.cloneDeep(response.data),
					})
					router.push(`/config/users/${id}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
					commit('SET_STATE', {
						actualUser: {},
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			users
				.create(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					dispatch('GET')
					router.replace('/config/users')
					commit('SET_STATE', {
						actualUser: {},
					})
				})
				.catch((error) => {
					// console.log('error-->', error)
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, { payload, redirect }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			users
				.update(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					dispatch('GET')
					if (redirect) {
						router.replace('/config/users')
						commit('SET_STATE', {
							actualUser: {},
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			users
				.toggle(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.users'),
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.users'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		usersList: (state) => state.usersList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		actualUser: (state) => state.actualUser,
		menu: (state) => state.menu,
	},
}
