import Vue from 'vue'
import Vuex from 'vuex'
import * as filesAPI from '@/services/files'
import utilities from '@/services/utilities'
import router from '@/router'
import _ from 'lodash'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		filesConfigurationList: [],
		actualRecord: {},
		fileURL: '',
		modalFileVisible: false,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_CONFIGURATIONS({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			filesAPI
				.getAll()
				.then((response) => {
					commit('SET_STATE', {
						filesConfigurationList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit, dispatch, state }, id) {
			if (state.filesConfigurationList.length) {
				commit('SET_STATE', {
					actualRecord: _.cloneDeep(state.filesConfigurationList.find((e) => e.id == id)),
				})
				router.push(`/config/files/${id}`)
			} else {
				dispatch('GET_ONE_WS', id)
			}
		},
		GET_ONE_WS({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})

			filesAPI
				.getOne(id)
				.then((response) => {
					commit('SET_STATE', {
						actualRecord: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
					commit('SET_STATE', {
						actualRecord: {},
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			filesAPI
				.create(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					dispatch('GET_CONFIGURATIONS')
					router.replace('/config/files')
					commit('SET_STATE', {
						actualRecord: {},
					})
				})
				.catch((error) => {
					// console.log('error-->', error)
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch }, { payload, redirect }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			filesAPI
				.update(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
					})
					if (redirect) {
						dispatch('GET_CONFIGURATIONS')
						router.replace('/config/files')
						commit('SET_STATE', {
							actualRecord: {},
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			filesAPI
				.toggle(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: i18n.t('general.messages.filesSettings'),
						description: response.message,
					})
					dispatch('GET_CONFIGURATIONS')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.filesSettings'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_FILE_URL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
				fileURL: '',
			})
			return new Promise((resolve, reject) => {
				filesAPI
					.getSecureURL(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						//
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		filesConfigurationList: (state) => state.filesConfigurationList,
		actualRecord: (state) => state.actualRecord,
		fileURL: (state) => state.fileURL,
		modalFileVisible: (state) => state.modalFileVisible,
	},
}
