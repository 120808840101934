export default Object.freeze([
	{
		id: '1',
		description: 'Programación',
	},
	{
		id: '2',
		description: 'Levantamiento',
	},
	{
		id: '3',
		description: 'Codificación',
	},
	{
		id: '4',
		description: 'Procesamiento',
	},
	{
		id: '5',
		description: 'Análisis',
	},
	{
		id: '6',
		description: 'Reporte',
	},
	{
		id: '7',
		description: 'Viáticos',
	},
	{
		id: '8',
		description: 'Incentivos',
	},
	{
		id: '9',
		description: 'Producto/materiales',
	},
])
