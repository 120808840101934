import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import utilities from '@/services/utilities'
import _ from 'lodash'

import * as authAPI from '@/services/auth'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		authorized: false,
		loading: false,
		loadingLabel: '',
		userData: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		LOGIN({ commit, dispatch }, payload) {
			//console.log('LOGIN::payload-->', payload)
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			authAPI
				.login(payload)
				.then((response) => {
					// console.log('response---', response)
					if (response) {
						dispatch('LOAD_CURRENT_ACCOUNT')
						Vue.prototype.$notification.success({
							message: 'Inicio de Sesión',
							description: utilities.objectValidate(response, 'message', 'Inicio de sesión correcto'),
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Inicio de Sesión',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al procesar la petición'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		LOAD_CURRENT_ACCOUNT({ commit, dispatch }) {
			commit('SET_STATE', {
				loading: true,
			})

			authAPI
				.currentAccount()
				.then((response) => {
					commit('SET_STATE', {
						userData: _.cloneDeep(response.data),
						authorized: utilities.objectValidate(response, 'data.id', false),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Inicio de Sesión',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al procesar la petición'),
					})
					dispatch('LOGOUT')
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		LOGOUT({ commit }) {
			//
			commit('SET_STATE', {
				authorized: false,
			})
			setTimeout(() => {
				localStorage.clear()
				if (router.currentRoute.path != '/auth/login' && router.currentRoute.path != '/auth/recovery-password/') {
					router.replace('/auth/login')
				}
			}, 100)
		},
		RECOVERY_PASSWORD({ commit }, payload) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			authAPI
				.recoveryPassword(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sistema',
						description: utilities.objectValidate(response, 'message', 'Se han enviado instrucciones al email registrado'),
					})
					router.replace('/auth/login')
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		UPDATE_PASSWORD({ commit }, payload) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			authAPI
				.updatePassword(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sistema',
						description: utilities.objectValidate(response, 'message', 'Contraseña actualizada de manera correcta'),
					})
					router.replace('/auth/login')
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
	},
	getters: {
		userData: (state) => state.userData,
		loading: (state) => state.loading,
		loadingLabel: (state) => state.loadingLabel,
	},
}
