import apiClient from '@/services/axios'
import store from 'store'
import utilities from '@/services/utilities'

export async function login(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/auth/login', payload)
			.then((response) => {
				if (utilities.objectValidate(response, 'data', false)) {
					const { token } = response.data.data
					if (token) {
						store.set('accessToken', token)
					}
					resolve(utilities.objectValidate(response, 'data', {}))
				} else {
					reject({})
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function currentAccount() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/auth/current-account')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function recoveryPassword(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/auth/recovery-password', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updatePassword(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/auth/update-password', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
