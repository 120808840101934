<template>
  <div :class="$style.breadcrumbs">
    <div :class="$style.path">
      <router-link to="/">Home</router-link>
      <template v-for="(item, index) in breadcrumb">
        <span v-if="index != 0" :key="index">
          <span :class="$style.arrow"></span>
          <span>{{ item.title }}</span>
        </span>
      </template>
      <span v-if="activeItem">
        <span :class="$style.arrow"></span>
        <strong :class="$style.current">{{ activeItem.title }}</strong>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import reduce from 'lodash/reduce'

export default {
  name: 'breadcrumbs',
  data() {
    return {
      activeItem: {},
      path: [],
    }
  },
  computed: {
    ...mapState(['settings']),
    menuData() {
      return [
        {
          category: true,
          title: 'Dashboards',
        },
        {
          title: 'Dashboard',
          key: 'dashboards',
          icon: 'fe fe-home',
          // roles: ['admin'], // set user roles with access to this route
          url: '/dashboard',
        },
        {
          title: this.$t('projectsModule.menu.projects'),
          key: 'projects',
          icon: 'fa fa-list',
          // roles: ['admin'], // set user roles with access to this route
          url: '/projects',
        },
        {
          title: this.$t('suppliersModule.menu.suppliers'),
          key: 'suppliers',
          icon: 'fa fa-users',
          // roles: ['admin'], // set user roles with access to this route
          url: '/suppliers',
        },
        {
          title: this.$t('catalogsModule.menu.generalCatalogs'),
          key: 'catalogs',
          icon: 'fa fa-file',
          // roles: [],
          children: [
            {
              title: this.$t('catalogsModule.menu.bakingInstitutions'),
              key: 'banking-institutions',
              url: '/catalogs/banking-institutions',
            },
            {
              title: this.$t('catalogsModule.menu.taxRegimes'),
              key: 'tax-regimes',
              url: '/catalogs/tax-regimes',
            },
          ],
        },
        {
          title: this.$t('systemModule.menu.system'),
          key: 'config',
          icon: 'fa fa-cog',
          // roles: ['admin'], // set user roles with access to this route
          children: [
            {
              title: this.$t('systemModule.menu.fileSettings'),
              key: 'files',
              url: '/config/files',
            },
            {
              title: this.$t('systemModule.menu.users'),
              key: 'users',
              url: '/config/users',
            },
          ],
        },
      ]
    },
    breadcrumb() {
      return this.getPath(this.menuData, this.$route.path)
    },
  },
  methods: {
    getPath(data, url, parents = []) {
      if (url === '/') {
        url = '/dashboard/alpha'
      }
      const items = reduce(
        data,
        (result, entry) => {
          if (result.length) {
            return result
          }
          if (entry.children) {
            const nested = this.getPath(entry.children, url, [entry].concat(parents))
            return (result || []).concat(nested.filter(e => !!e))
          }
          if (entry.url === url) {
            return [entry].concat(parents)
          }
          return result
        },
        [],
      )
      this.activeItem = items[0]
      return items
    },
  },
  mounted: function () {
    // this.breadcrumb = this.getPath(this.menuData, this.$route.path)
  },
  watch: {
    $route(to) {
      // this.breadcrumb = this.getPath(this.menuData, to.path)
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
