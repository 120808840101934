import Vue from 'vue'
import Vuex from 'vuex'
import * as customersAPI from '@/services/customers'
import utilities from '@/services/utilities'
import router from '@/router'
import _ from 'lodash'
import { i18n } from '../../localization.vue'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		customersList: [],
		customersListFiltered: [],
		actualRecord: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			customersAPI
				.getAll(payload)
				.then((response) => {
					commit('SET_STATE', {
						customersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Clientes',
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			return new Promise((resolve, reject) => {
				customersAPI
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualRecord: _.cloneDeep(response.data),
						})
						if (router.currentRoute.params.id != id) {
							router.push(`/customers/${id}`)
						}
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
						})
						commit('SET_STATE', {
							actualRecord: {},
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, { payload, returnData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
						})
						if (!returnData) {
							dispatch('GET_ONE', response.data.id)
						} else {
							dispatch('GET')
						}
						resolve(response)
					})
					.catch((error) => {
						// console.log('error-->', error)
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
						})
						dispatch('GET_ONE', payload.customer_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async TOGGLE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				customersAPI
					.toggle(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Clientes',
							description: response.message,
						})
						dispatch('GET')

						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Clientes',
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		customersList: (state) => state.customersList,
		customersListFiltered: (state) => state.customersListFiltered,
		actualRecord: (state) => state.actualRecord,
	},
}
