const messages = {
	menu: {
		projects: 'Proyectos',
	},
	tableHeaders: {
		id: 'ID',
		rfc: 'RFC',
		key: 'Código/Clave',
		name: 'Nombre',
		description: 'Descripción',
		totalBudget: 'Presupuesto Total',
	},
	tabs: {
		generalData: 'Datos Generales',
		accountsCatalog: 'Catálogo de Cuentas',
		crewListSuppliers: 'Crew / Proveedores',
	},
	edit: {
		accountsCatalog: {
			import: 'Importar catálogo',
			download: 'Descargar catálogo',
			add: 'Añadir registro',
			title: 'Registro de Cuenta',
			sector: 'Sector',
			account: 'Cuenta',
			subaccount: 'Subcuenta',
			sub_subaccount: 'Sub-subcuenta',
			description: 'Descripción',
			budget: 'Presupuesto',
		},
	},
}

export default messages
