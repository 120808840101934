export default Object.freeze({
	structure: {
		area_id: '',
		expense_type: '',
		priority: '1',
		beneficiary: '',
		supplier: '',
		advance_ticket: '',
		payment_method_index: '',
		payment_method_data: {},
		order: {
			supplier: '',
			quantity: 1,
			unit_of_measurement: '',
			description: '',
			unit_price: 0,
			link: '',
			definers: {
				unit_price: false,
				supplier: false,
			},
		},
		comments: '',
		price: 0,
		satPaymentMethod: 'PUE',
		partialities: '',
		advanceAmount: 0,
		paymentDate: '',
		cie: '',
		reference: '',
		area: '',
	},
	order: {
		description: '',
		measurement_unit: '',
		quantity: 1,
		amount: 0,
	},
})
