const messages = {
	menu: {
		projects: 'Projects',
	},
	tableHeaders: {
		id: 'ID',
		rfc: 'RFC',
		key: 'Code/Key',
		name: 'Name',
		description: 'Description',
		totalBudget: 'Total Budget',
	},
	tabs: {
		generalData: 'General Data',
		accountsCatalog: 'Accounts Catalog',
		crewListSuppliers: 'Crew / Suppliers',
	},
	edit: {
		accountsCatalog: {
			import: 'Import catalog',
			download: 'Download catalog',
			add: 'Add record',
			title: 'Account Record',
			sector: 'Sector',
			account: 'Account',
			subaccount: 'Subaccount',
			sub_subaccount: 'Sub-subaccount',
			description: 'Description',
			budget: 'Budget',
		},
	},
}

export default messages
