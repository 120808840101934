export default Object.freeze({
	'super-admin': {
		label: 'Super Administrador',
	},
	'admin': {
		label: 'Administrador',
	},
	'coordinator': {
		label: 'Lider de Proyecto',
	},
	'operations': {
		label: 'Operaciones',
	},
	'supplier': {
		label: 'Proveedor',
	},
	'accounting': {
		label: 'Contabilidad',
	},
})
