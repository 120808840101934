
const messages = {
	menu: {
		system: 'Sistema',
		fileSettings: 'Configuración de archivos',
		users: 'Usuarios',
	},
	fileSettings: {
		tableHeaders: {
			description: 'Descripción',
			extension: 'Extensión',
			maxWeight: 'Peso máximo',
			periodicity: 'Periodicidad',
		},
	},
	users: {
		tableHeaders: {
			id: 'ID',
			fullName: 'Nombre completo',
			email: 'Email',
			phone: 'Teléfono',
			role: 'Rol',
		},
		edit: {
			title: 'Información General',
			names: 'Nombre(s)',
			surname: 'Apellidos',
			password: 'Contraseña',
			confirmPassword: 'Confirmar contraseña',
			generatePassword: 'Generar contraseña',
			passwordMsg: 'Ingrese contraseña de al menos 6 caracteres.',
			confirmPasswordMsg: 'Las contraseñas no coinciden.',
			setPassword: 'Ingresa contraseña para continuar',
		},
	},
}

export default messages;
