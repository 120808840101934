const messages = {
	menu: {
		generalCatalogs: 'General Catalogs',
		bakingInstitutions: 'Baking Institutions',
		taxRegimes: 'Tax Regimes',
		sectors: 'Sectors - Contable Accounts',
	},
	bakingInstitutions: {
		tableHeaders: {
			id: 'ID',
			shortName: 'Short name',
			institutionName: 'Institution name',
		},
		add: {
			title: 'Add Bank Institution record',
		},
		edit: {
			title: 'Edit Bank Institution record',
			code: 'Code',
		},
	},
	taxRegimes: {
		tableHeaders: {
			satKey: 'Code',
			name: 'Name',
			vat: 'IVA',
			isr: 'ISR withholding',
			vatWithholding: 'IVA withholding',
		},
		add: {
			title: 'Add Tax Regimes record',
		},
		edit: {
			title: 'Edit Tax Regimes record',
		},
	},
	sectors: {
		tableHeaders: {
			name: 'Name',
			description: 'Description',
		},
		add: {
			title: 'Add record',
		},
		edit: {
			title: 'Edit record',
		},
	},
}

export default messages
