import Vue from 'vue'
import Vuex from 'vuex'
import * as configurationsAPI from '@/services/configurations'
import utilities from '@/services/utilities'
import { i18n } from '../../localization.vue'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		configurations: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			configurationsAPI
				.get()
				.then((response) => {
					console.log(response)
					commit('SET_STATE', {
						configurations: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.configurations'),
						description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.successGetInfo')),
					})
					commit('SET_STATE', {
						configurations: {},
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		configurations: (state) => state.configurations,
	},
}
