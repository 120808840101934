import localeAntd from 'ant-design-vue/lib/locale-provider/en_US'
// System
import * as systemModule from './system.en-US'
// General catalogs
import * as catalogsModule from './generalCatalogs.en-US'
// Suppliers
import * as suppliersModule from './suppliers.en-US'
// Projects
import * as projectsModule from './projects.en-US'

const messages = {
	topBar: {
		issuesHistory: 'Issues History',
		projectManagement: 'Project Management',
		typeToSearch: 'Search...',
		findPages: 'Find pages...',
		actions: 'Actions',
		status: 'Status',
		profileMenu: {
			hello: 'Hello',
			billingPlan: 'Billing Plan',
			role: 'Role',
			email: 'Email',
			phone: 'Phone',
			editProfile: 'Edit Profile',
			logout: 'Logout',
		},
	},
	general: {
		loading: 'Getting information...',
		add: 'Add',
		actions: 'Actions',
		yes: 'Yes',
		yesContinue: 'Yes, continue',
		no: 'No',
		confirm: 'Confirm',
		cancel: 'Cancel',
		back: 'Back',
		create: 'Create',
		save: 'Save',
		update: 'Update',
		delete: 'Delete',
		requiredField: 'Required field',
		validEmail: 'Enter a valid email',
		close: 'Close',
		deleteMessage: {
			title: 'Atention',
			message: 'Do you want to delete the record?',
		},
		updateMessage: {
			title: 'Atention',
			message: 'Do you want to update the record?',
		},
		messages: {
			filesSettings: 'Settings - Files',
			users: 'Users',
			bakingInstitutions: 'Bank Institutions',
			taxRegimes: 'Tax Regimes',
			suppliers: 'Suppliers',
			projects: 'Projects',
			sectors: 'Sectors - Contables Accounts',
			updatingInformation: 'Updating information',
			errorGet: 'Failed to querying the registry',
			errorGetListo: 'Failed to querying the registry from LISTO',
			successAdd: 'Record generated successfully',
			errorAdd: 'Failed to generating record',
			successEdit: 'Record updated successfully',
			errorEdit: 'Failed to update record',
			errorDisable: 'Failed to disable logging',
			successEditPlural: 'Records updated successfully',
			errorEditPlural: 'Failed to update records',
			successGetInfo: 'Information obtained successfully',
			errorGetInfo: 'Failed to obtain information',
			configurations: 'Configurations',
		},
	},
	systemModule: systemModule.default,
	catalogsModule: catalogsModule.default,
	suppliersModule: suppliersModule.default,
	projectsModule: projectsModule.default,
}

export default {
	locale: 'en-US',
	localeAntd,
	messages,
}
