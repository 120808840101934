const messages = {
	menu: {
		suppliers: 'Suppliers',
	},
	search: {
		suppliers: 'Suppliers',
		crewList: 'Crew',
		all: 'All',
		placeholder: 'Search in suppliers...',
	},
	tableHeaders: {
		id: 'ID',
		name: "Provider's name",
		recordType: 'Type of register',
		nacionality: 'Nacionality',
		email: 'Email',
		phones: 'Telephones',
	},
	tabs: {
		generalInfo: 'General information',
		bankInfo: 'Bank information',
		taxInfo: 'Tax information',
		emergencyContacts: 'Emergency contacts',
		files: 'Files',
		dealMemo: "Deal Memo's",
	},
	edit: {
		reload: 'Reload',
		generalInfo: {
			supplier: 'Supplier',
			crewMember: 'Crew',
			link: 'Single link',
			comments: 'Comments/Observations',
			national: 'National',
			foreign: 'Foreign',
			copyLink: 'Copy link',
		},
		bankInfo: {
			bankInstution: 'Bank institution',
			beneficiary: 'Name of the beneficiary',
			accountNumber: 'Account number',
			keyNumber: 'CLABE number',
			accountType: 'Account type',
		},
		taxInfo: {
			taxRegime: 'Tax regime',
			recordTyupe: 'Type of register',
			businessName: 'Business name',
			rfc: 'RFC',
			street: 'Street',
			externalNumber: 'Outdoor Number',
			internalNumber: 'Interior number',
			cp: 'Postal Code',
			neighborhood: 'Neighborhood',
			municipality: 'Municipality',
			state: 'State',
			country: 'Country',
			contactPhone: 'Telephone contact',
			notificationEmail: 'notification email',
			physicalPerson: 'Physical person',
			moralPerson: 'Moral person',
		},
		emergencyContacts: {
			fullName: 'Full name',
			phone: 'Contact telephone',
			tableHeaders: {
				name: 'Name',
				phone: 'Telephone',
			},
			noData: 'No contacts have been registered',
		},
		productionPhases: {
			projectAccount: 'Account',
			phaseStart: 'Phase start date',
			phaseEnd: 'Phase end date',
			tableHeaders: {
				info: 'Phase Details',
			},
			noData: 'No phases have been registered',
		},
		files: {},
		dealMemos: {},
	},
}

export default messages
