import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/projects')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOne(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/projects/get-one`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/projects', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function update(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/projects`, payload)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggle(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.put(`/projects/toggle/${id}`)
			.then((response) => {
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getInvoices(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/projects/invoices`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function toggleSuppliers(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/suppliers/toggle-relation`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getSupplierServices(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/projects/${payload.project_id}/supplier/${payload.supplier_id}/services`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function deleteSupplierService(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/projects/supplier-services/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function addSupplierService(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/projects/${payload.project_id}/supplier/${payload.supplier_id}/services`, payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function generalNotificationClosedProject(project_id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/projects/${project_id}/notifications/close-project/general`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function particularNotificationClosedProject(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/projects/${payload.project_id}/notifications/close-project/${payload.supplier_id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
