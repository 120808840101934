import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

import * as authAPI from '@/services/auth'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		id: '',
		name: '',
		role: '',
		email: '',
		avatar: '',
		authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
		spinnerLoader: false,
		spinnerLoaderLabel: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
