import localeAntd from 'ant-design-vue/lib/locale-provider/es_ES'
// System
import * as systemModule from './system.es-ES'
// General catalogs
import * as catalogsModule from './generalCatalogs.es-ES'
// Suppliers
import * as suppliersModule from './suppliers.es-ES'
// Projects
import * as projectsModule from './projects.es-ES'

const messages = {
	system: {
		auth: {
			login: {
				header: 'Ingresa a tu cuenta',
				login: 'Ingresar',
				forgotPassword: '¿Olvidaste tu contraseña?',
				form: {
					username: {
						required: 'Campo requerido',
						placeholder: 'Usuario',
					},
					password: {
						required: 'Campo requerido',
					},
				},
			},
			forgotPassword: {
				header: 'Recuperación de contraseña',
				button: 'Ingresar',
				form: {
					username: {
						required: 'Campo requerido',
						placeholder: 'Usuario',
					},
				},
				backLabel: 'Iniciar sesión',
			},
			recoveryPassword: {
				header: 'Recuperación de Contraseña',
				button: 'Establecer nueva contraseña',
				form: {
					password: {
						required: 'Campo requerido',
					},
					confirmPassword: {
						required: 'Campo requerido',
					},
				},
			},
		},
	},
	topBar: {
		issuesHistory: 'Issues History',
		projectManagement: 'Project Management',
		typeToSearch: 'Search...',
		findPages: 'Find pages...',
		actions: 'Actions',
		status: 'Status',
		profileMenu: {
			hello: 'Hola',
			role: 'Rol',
			email: 'Email',
			phone: 'Teléfono',
			editProfile: 'Editar Perfil',
			logout: 'Salir',
		},
	},
	general: {
		loading: 'Obteniendo información...',
		add: 'Añadir',
		actions: 'Acciones',
		yes: 'Sí',
		yesContinue: 'Sí, continuar',
		no: 'No',
		confirm: 'Confirmar',
		cancel: 'Cancelar',
		back: 'Regresar',
		create: 'Crear',
		save: 'Guardar',
		update: 'Editar',
		delete: 'Eliminar',
		requiredField: 'Campo requerido',
		validEmail: 'Ingersa un email válido',
		close: 'Cerrar',
		deleteMessage: {
			title: 'Atención',
			message: '¿Desea eliminar el registro?',
		},
		updateMessage: {
			title: 'Atención',
			message: '¿Desea actualizar los registros?',
		},
		messages: {
			filesSettings: 'Configuración - Archivos',
			users: 'Usuarios',
			bakingInstitutions: 'Instituciones Bancarias',
			taxRegimes: 'Regímenes Tributarios',
			suppliers: 'Proveedores',
			projects: 'Proyectos',
			sectors: 'Sectores - Cuentas contables',
			updatingInformation: 'Actualizando información',
			errorGet: 'Error al consultar el registro',
			errorGetListo: 'Error al oobtener información de LISTO',
			successAdd: 'Registro generado con éxito',
			errorAdd: 'Error al generar el registro',
			successEdit: 'Registro actualizado con éxito',
			errorEdit: 'Error al actualizar el registro',
			errorDisable: 'Error al deshabilitar el registro',
			successEditPlural: 'Registros actualizados con éxito',
			errorEditPlural: 'Error al actualizar los registros',
			successGetInfo: 'Información obtenida con éxito',
			errorGetInfo: 'Failed to obtain information',
			configurations: 'Configuraciones',
		},
	},
	systemModule: systemModule.default,
	catalogsModule: catalogsModule.default,
	suppliersModule: suppliersModule.default,
	projectsModule: projectsModule.default,
}

export default {
	locale: 'es-ES',
	localeAntd,
	messages,
}
