<template>
	<div :class="{
		[$style.menu]: true,
		[$style.white]: settings.menuColor === 'white',
		[$style.gray]: settings.menuColor === 'gray',
		[$style.dark]: settings.menuColor === 'dark',
	}">
		<div :class="$style.logoContainer">
			<div :class="$style.logo">
				<img src="resources/images/icon.png" class="mr-2" alt="Origami Group" width="40" />
				<div :class="$style.name">{{ settings.logo }}</div>
			</div>
		</div>
		<div :class="$style.navigation">
			<a-menu :mode="'horizontal'" :selectedKeys="selectedKeys" @click="handleClick">
				<template v-for="item in menuData">
					<template v-if="!item.roles || item.roles.includes(userData.role)">
						<item v-if="!item.children && !item.category" :menu-info="item" :styles="$style" :key="item.key" />
						<sub-menu v-if="item.children" :menu-info="item" :styles="$style" :key="item.key" />
					</template>
				</template>
			</a-menu>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'
import find from 'lodash/find'
import SubMenu from './partials/submenu'
import Item from './partials/item'

export default {
	name: 'menu-top',
	components: { SubMenu, Item },
	computed: {
		...mapState(['settings']),
		...mapGetters('auth', ['userData']),
		menuData() {
			return [
				{
					category: true,
					title: 'Dashboards',
				},
				{
					title: 'Clientes',
					key: 'customers',
					icon: 'fa fa-user-plus',
					roles: ['super-admin', 'admin'],
					url: '/customers',
				},
				{
					title: this.$t('projectsModule.menu.projects'),
					key: 'projects',
					icon: 'fa fa-list',
					roles: ['super-admin', 'admin', 'coordinator', 'operations'],
					url: '/projects',
				},
				{
					title: this.$t('suppliersModule.menu.suppliers'),
					key: 'suppliers',
					icon: 'fa fa-users',
					roles: ['super-admin', 'admin', 'coordinator', 'operations'],
					url: '/suppliers',
				},
				{
					title: 'Pagos',
					key: 'purchase-orders',
					icon: 'fa fa-dollar',
					roles: ['super-admin', 'admin', 'accounting'],
					url: '/purchase-orders',
				},
				// {
				// 	title: 'Reportes',
				// 	key: 'dashboards',
				// 	icon: 'fe fe-chart',
				// 	// roles: ['admin'], // set user roles with access to this route
				// 	url: '/dashboard',
				// },
				{
					title: this.$t('catalogsModule.menu.generalCatalogs'),
					key: 'catalogs',
					icon: 'fa fa-file',
					roles: ['super-admin', 'admin'],
					children: [
						{
							title: this.$t('catalogsModule.menu.bakingInstitutions'),
							key: 'banking-institutions',
							url: '/catalogs/banking-institutions',
						},
						{
							title: this.$t('catalogsModule.menu.taxRegimes'),
							key: 'tax-regimes',
							url: '/catalogs/tax-regimes',
						},
					],
				},
				{
					title: this.$t('systemModule.menu.system'),
					key: 'config',
					icon: 'fa fa-cog',
					roles: ['super-admin', 'admin'],
					children: [
						{
							title: this.$t('systemModule.menu.users'),
							key: 'users',
							url: '/config/users',
						},
					],
				},
				{
					title: 'Mis proyectos',
					key: 'supplier_projects',
					icon: 'fa fa-list',
					roles: ['supplier'],
					url: '/supplier/projects',
				},
				{
					title: 'Mis datos generales',
					key: 'supplier_info',
					icon: 'fa fa-user',
					roles: ['supplier'],
					url: '/supplier/account',
				},
			]
		},
	},
	mounted() {
		this.selectedKeys = store.get('app.menu.selectedKeys') || []
		this.setSelectedKeys()
	},
	data() {
		return {
			selectedKeys: [],
			openKeys: [],
		}
	},
	watch: {
		'settings.isMenuCollapsed'() {
			this.openKeys = []
		},
		$route() {
			this.setSelectedKeys()
		},
	},
	methods: {
		handleClick(e) {
			if (e.key === 'settings') {
				this.$store.commit('CHANGE_SETTING', { setting: 'isSettingsOpen', value: true })
				return
			}
			store.set('app.menu.selectedKeys', [e.key])
			this.selectedKeys = [e.key]
		},
		setSelectedKeys() {
			const pathname = this.$route.path
			const menuData = this.menuData.concat()
			const flattenItems = (items, key) =>
				items.reduce((flattenedItems, item) => {
					flattenedItems.push(item)
					if (Array.isArray(item[key])) {
						return flattenedItems.concat(flattenItems(item[key], key))
					}
					return flattenedItems
				}, [])
			const selectedItem = find(flattenItems(menuData, 'children'), ['url', pathname])
			this.selectedKeys = selectedItem ? [selectedItem.key] : []
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
