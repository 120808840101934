const messages = {
	menu: {
		generalCatalogs: 'Catálogos generales',
		bakingInstitutions: 'Instituciones Bancarias',
		taxRegimes: 'Regímenes Tributarios',
		sectors: 'Sectores - Cuentas contables',
	},
	bakingInstitutions: {
		tableHeaders: {
			id: 'ID',
			shortName: 'Nombre corto',
			institutionName: 'Nombre de la Institución Bancaria',
		},
		add: {
			title: 'Añadir registro de Institución Bancaria',
		},
		edit: {
			title: 'Editar registro de Institución Bancaria',
			code: 'Código',
		},
	},
	taxRegimes: {
		tableHeaders: {
			satKey: 'Clave SAT',
			name: 'Nombre',
			vat: 'IVA',
			isr: 'Retención ISR',
			vatWithholding: 'Retención de IVA',
		},
		add: {
			title: 'Añadir registro de Regímenes Tributarios',
		},
		edit: {
			title: 'Editar registro de Regímenes Tributarios',
		},
	},
	sectors: {
		tableHeaders: {
			name: 'Nombre',
			description: 'Descripción',
		},
		add: {
			title: 'Añadir registro de sector',
		},
		edit: {
			title: 'Editar registro de sector',
		},
	},
}

export default messages
