import Vue from 'vue'
import Vuex from 'vuex'
import * as suppliersAPI from '@/services/suppliers'
import utilities from '@/services/utilities'
import router from '@/router'
import _ from 'lodash'
import { i18n } from '../../localization.vue'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		suppliersList: [],
		suppliersListFiltered: [],
		actualRecord: {
			bank: [],
			contacts: [],
			files: [],
			general: {},
			invoices: {
				data: [],
				pagination: {
					current: 0,
					total: 0,
				},
			},
			tax: {},
			projects: [],
		},
		actualContactRecord: {},
		rfcTokenList: [],
		modalFileVisible: false,
		fileURL: '',
		actualSupplier: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.getAll(payload)
					.then((response) => {
						commit('SET_STATE', {
							suppliersList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.getOne(id)
					.then((response) => {
						commit('SET_STATE', {
							actualRecord: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGet')),
						})
						commit('SET_STATE', {
							actualRecord: {},
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successAdd')),
						})
						dispatch('GET_ONE', response.data.supplier_id)

						resolve(response)
					})
					.catch((error) => {
						// console.log('error-->', error)
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorAdd')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPDATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEdit')),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE_RELATIONSHIP({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.createRelationship(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(response, 'message', i18n.t('general.messages.successEditPlural')),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEditPlural')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async TOGGLE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.toggle(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})

						if (payload.section == 'suppliers/bank-data' || payload.section == 'suppliers/emergency-contact') {
							dispatch('GET_ONE', payload.supplier_id)
						} else {
							dispatch('GET')
						}

						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		LISTO_TOKENS({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})
			suppliersAPI
				.rfcTokenList()
				.then((response) => {
					commit('SET_STATE', {
						rfcTokenList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: i18n.t('general.messages.suppliers'),
						//description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGetListo')),
						description: 'Se usarán los registros pre-codificados',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async UPLOAD_FILE({ commit, dispatch }, formData) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.upload(formData)
					.then(async (response) => {
						// console.log('upload::upload->', response)
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', formData.get('project_id'))
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},

		async GET_INVOICES({ state, commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.loading'),
			})

			return new Promise((resolve, reject) => {
				suppliersAPI
					.getInvoices(payload)
					.then((response) => {
						let actualRecord = {
							...state.actualRecord,
							invoices: {
								data: _.cloneDeep(response.data.data),
								pagination: _.cloneDeep(response.data.pagination),
							},
						}
						commit('SET_STATE', {
							actualRecord,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorGetInfo')),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async TOGGLE_FILES({ commit, dispatch }, { ids, supplier_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.toggleFiles({ ids })
					.then((response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async SEARCH({ commit }, { searchText }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
				suppliersListFiltered: [],
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.search({ searchText })
					.then(async (response) => {
						commit('SET_STATE', {
							suppliersListFiltered: _.cloneDeep(response.data),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async CREATE_PRODUCTION_DATA({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.createProductionData(payload)
					.then(async (response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', payload.supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async UPDATE_PRODUCTION_DATA({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.updateProductionData(payload)
					.then(async (response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', payload.supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async CREATE_PRODUCTION_PHASE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.createProductionPhase(payload)
					.then(async (response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', payload.supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async UPDATE_PRODUCTION_PHASE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.updateProductionPhase(payload)
					.then(async (response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', payload.supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async DELETE_PRODUCTION_PHASE({ commit, dispatch }, { id, supplier_id }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: i18n.t('general.messages.updatingInformation'),
			})
			return new Promise((resolve, reject) => {
				suppliersAPI
					.deleteProductionPhase(id)
					.then(async (response) => {
						Vue.prototype.$notification.success({
							message: i18n.t('general.messages.suppliers'),
							description: response.message,
						})
						dispatch('GET_ONE', supplier_id)
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: i18n.t('general.messages.suppliers'),
							description: utilities.objectValidate(error, 'response.data.message', i18n.t('general.messages.errorEdit')),
						})
						commit('SET_STATE', {
							spinnerLoader: false,
						})
						reject(error)
					})
			})
		},
		async SET_NEW_PASSWORD({ commit }, payload) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			suppliersAPI
				.setNewPassword(payload)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Proveedores',
						description: utilities.objectValidate(response, 'message', 'Contraseña actualizada de manera correcta'),
					})
					router.replace('/auth/login')
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		async VALIDATE_FILE({ commit, dispatch }, { id, supplier_id }) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			suppliersAPI
				.validateFile(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Proveedores',
						description: utilities.objectValidate(response, 'message', 'Contraseña actualizada de manera correcta'),
					})
					dispatch('GET_ONE', supplier_id)
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
		async SEND_CREDENTIALS({ commit, dispatch }, id) {
			commit('SET_STATE', {
				loading: true,
				loadingLabel: 'Procesando...',
			})

			suppliersAPI
				.sendCredentials(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Proveedores',
						description: utilities.objectValidate(response, 'message', 'Se han enviado los accesos correspondientes'),
					})
					dispatch('GET')
				})
				.finally(() => {
					commit('SET_STATE', {
						loading: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		suppliersList: (state) => state.suppliersList,
		suppliersListFiltered: (state) => state.suppliersListFiltered,
		actualRecord: (state) => state.actualRecord,
		supplier_type: (state) => utilities.objectValidate(state, 'actualRecord.general.supplier_type', false),
		actualContactRecord: (state) => state.actualContactRecord,
		rfcTokenList: (state) => state.rfcTokenList,
		modalFileVisible: (state) => state.modalFileVisible,
		fileURL: (state) => state.fileURL,
		actualSupplier: (state) => state.actualSupplier,
	},
}
