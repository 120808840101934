import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	// mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: 'dashboard',
			component: MainLayout,
			meta: {
				authRequired: true,
				hidden: true,
			},
			children: [
				// Dashboard
				{
					path: '/dashboard',
					meta: {
						title: 'Dashboard',
					},
					component: () => import('./views/dashboard'),
				},
				// Projects
				{
					path: '/projects',
					meta: {
						title: 'Proyectos',
					},
					component: () => import('./views/projects'),
				},
				{
					path: '/projects/:id',
					meta: {
						title: 'Proyectos',
					},
					component: () => import('./views/projects/detail'),
				},
				// Supplier Projects (role)
				{
					path: '/supplier/projects',
					meta: {
						title: 'Proyectos',
					},
					component: () => import('./views/suppliers/projects'),
				},
				{
					path: '/supplier/projects/:id',
					meta: {
						title: 'Proyectos',
					},
					component: () => import('./views/suppliers/projects/detail'),
				},
				{
					path: '/supplier/account',
					meta: {
						title: 'Mis datos generales',
					},
					component: () => import('./views/suppliers/account'),
				},
				// Suppliers
				{
					path: '/suppliers',
					meta: {
						title: 'Proveedores',
					},
					component: () => import('./views/suppliers'),
				},
				{
					path: '/suppliers/:id',
					meta: {
						title: 'Proveedores',
					},
					component: () => import('./views/suppliers/detail'),
				},
				// Purchase Orders
				{
					path: '/purchase-orders',
					meta: {
						title: 'Órdenes de Compra',
					},
					component: () => import('./views/purchase-orders'),
				},
				{
					path: '/purchase-orders/:id',
					meta: {
						title: 'Órdenes de Compra',
					},
					component: () => import('./views/purchase-orders/details'),
				},
				// Customers
				{
					path: '/customers',
					meta: {
						title: 'Clientes',
					},
					component: () => import('./views/customers'),
				},
				{
					path: '/customers/:id',
					meta: {
						title: 'Clientes',
					},
					component: () => import('./views/customers/detail'),
				},
				// Catalogs
				{
					path: '/catalogs/banking-institutions',
					meta: {
						title: 'Instituciones Bancarias',
					},
					component: () => import('./views/bankingInstitutions'),
				},
				{
					path: '/catalogs/tax-regimes',
					meta: {
						title: 'Regímenes Tributarios',
					},
					component: () => import('./views/taxRegimes'),
				},
				// Configuration
				{
					path: '/config/users',
					meta: {
						title: 'Usuarios del sistema',
					},
					component: () => import('./views/config/users'),
				},
				{
					path: '/config/users/:id',
					meta: {
						title: 'Usuarios del sistema - Detalle',
					},
					component: () => import('./views/config/users/detail'),
				},
				{
					path: '/config/profile',
					meta: {
						title: 'Perfil',
					},
					component: () => import('./views/config/profile'),
				},
			],
		},

		// System Pages
		{
			path: '/auth',
			component: AuthLayout,
			redirect: 'auth/login',
			children: [
				{
					path: '/auth/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('./views/auth/404'),
				},
				{
					path: '/auth/login',
					meta: {
						title: 'Inicio de Sesión',
					},
					component: () => import('./views/auth/login'),
				},
				{
					path: '/auth/forgot-password',
					meta: {
						title: 'Recuperación de Contraseña',
					},
					component: () => import('./views/auth/forgot-password'),
				},
				{
					path: '/auth/recovery-password',
					meta: {
						title: 'Recuperación de Contraseña',
					},
					component: () => import('./views/auth/recovery-password'),
				},
				{
					path: '/auth/set-password/:token',
					meta: {
						title: 'Recuperación de Contraseña',
					},
					component: () => import('./views/auth/set-password'),
				},
			],
		},

		// Redirect to 404
		{
			path: '*',
			redirect: 'auth/404',
			hidden: true,
		},
	],
})

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.authRequired)) {
		// console.log('record.meta.authRequired', record.meta.authRequired)
		if (!store.state.auth.authorized) {
			next({
				path: '/auth/login',
				query: { redirect: to.fullPath },
			})
		} else {
			next()
		}
	} else {
		next()
	}
})

export default router
