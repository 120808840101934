import Vue from 'vue'
import Vuex from 'vuex'
import settings from './settings'

import auth from './auth'
import user from './user'
import users from './users'
import files from './files'
import projects from './projects'
import suppliers from './suppliers'
import bankingInstitutions from './bankingInstitutions'
import taxRegimes from './taxRegimes'
import sectors from './sectors'
import configurations from './configurations'
import dashboard from './dashboard'
import weekPeriods from './weekPeriods'
import customers from './customers'
import purchaseOrders from './purchase-orders'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		settings,
		auth,
		user,
		users,
		files,
		projects,
		suppliers,
		bankingInstitutions,
		taxRegimes,
		sectors,
		configurations,
		dashboard,
		weekPeriods,
		customers,
		purchaseOrders,
	},
	state: {},
	mutations: {},
	actions: {},
})
