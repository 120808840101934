<template>
	<a-dropdown :trigger="['click']" placement="bottomLeft">
		<div :class="$style.dropdown">
			<a-badge :count="count">
				<a-avatar shape="square" icon="user" size="large" :class="$style.avatar" />
			</a-badge>
		</div>
		<a-menu slot="overlay">
			<a-menu-item>
				<div>
					<strong>{{ $t('topBar.profileMenu.hello') }}, {{ userData.name }} {{ userData.surname }}</strong>
				</div>
				<div>
					<strong class="mr-1">{{ $t('topBar.profileMenu.role') }}:</strong>
					{{ roles[userData.role].label }}
				</div>
			</a-menu-item>
			<a-menu-divider />
			<a-menu-item>
				<div>
					<strong class="mr-1">{{ $t('topBar.profileMenu.email') }}:</strong>
					{{ userData.email }}
				</div>
				<div>
					<strong class="mr-1">{{ $t('topBar.profileMenu.phone') }}:</strong>
					{{ userData.phone }}
				</div>
			</a-menu-item>
			<a-menu-divider v-if="userData.role != 'supplier'" />
			<a-menu-item v-if="userData.role != 'supplier'">
				<router-link to="/config/profile"><i class="fe fe-user mr-2"></i> {{ $t('topBar.profileMenu.editProfile') }}</router-link>
			</a-menu-item>
			<a-menu-divider />
			<a-menu-item>
				<a href="javascript: void(0);" @click="logout">
					<i class="fe fe-log-out mr-2"></i>
					{{ $t('topBar.profileMenu.logout') }}
				</a>
			</a-menu-item>
		</a-menu>
	</a-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { roles } from '@/constants'

export default {
	computed: {
		...mapGetters('auth', ['userData']),
	},
	data: function () {
		return {
			roles,
			count: 0,
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('auth/LOGOUT')
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
