const messages = {
	menu: {
		suppliers: 'Proveedores',
	},
	search: {
		suppliers: 'Proveedores',
		crewList: 'Crew',
		all: 'Todos',
		placeholder: 'Búsqueda en proveedores...',
	},
	tableHeaders: {
		id: 'ID',
		name: 'Nombre del proveedor (nombre común)',
		recordType: 'Tipo de registro',
		nacionality: 'Tipo de Proveedor',
		email: 'Email',
		phones: 'Teléfono',
	},
	tabs: {
		generalInfo: 'Información general',
		bankInfo: 'Información bancaria',
		taxInfo: 'Información fiscal',
		emergencyContacts: 'Contactos de emergencia',
		files: 'Archivos',
		dealMemo: 'Memo de negociación',
	},
	edit: {
		reload: 'Recargar',
		generalInfo: {
			supplier: 'Proveedor',
			crewMember: 'Crew',
			link: 'Link único',
			comments: 'Comentarios/Observaciones',
			national: 'Nacional',
			foreign: 'Extranjero',
			copyLink: 'Copiar link',
		},
		bankInfo: {
			bankInstution: 'Institución bancaria',
			beneficiary: 'Nombre del beneficiario',
			accountNumber: 'Número de cuenta',
			keyNumber: 'Número CLABE',
			accountType: 'Tipo de cuenta',
		},
		taxInfo: {
			taxRegime: 'Régimen fiscal',
			recordTyupe: 'Tipo de registro',
			businessName: 'Razón social',
			rfc: 'RFC',
			street: 'Calle',
			externalNumber: 'Número exterior',
			internalNumber: 'Número interior',
			cp: 'Código postal',
			neighborhood: 'Colonia',
			municipality: 'Municipio',
			state: 'Estado',
			country: 'País',
			contactPhone: 'Teléfono de contacto',
			notificationEmail: 'Email de notificación',
			physicalPerson: 'Persona física',
			moralPerson: 'Persona moral',
		},
		emergencyContacts: {
			fullName: 'Nombre completo',
			phone: 'Teléfono de contacto',
			tableHeaders: {
				name: 'Nombre',
				phone: 'Teléfono',
			},
			noData: 'No se han registrado contactos',
		},
		productionPhases: {
			projectAccount: 'Cuenta presupuestal',
			phaseStart: 'Fecha de Inicio',
			phaseEnd: 'Fecha de Fin',
			tableHeaders: {
				info: 'Detalles de la Fase',
			},
			noData: 'No se han registrado etapas',
		},
		files: {},
		dealMemos: {},
	},
}

export default messages
